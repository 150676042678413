import { addMonths, format, parse, startOfMonth, subDays } from "date-fns";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import ReactLoading from 'react-loading';

import { DailySchedule } from "../../../components/DailySchedule";
import { MonthlySchedule } from "../../../components/MonthlySchedule";
import MainLayout from "../../../layouts/MainLayout";
import { SelectScheduleArticleType } from "../../../components/SelectArticleType";
//import { SelectDisplayCountPerHour } from "../../../components/SelectDisplayCountPerHour";
import { useArticleSuspend } from "../../../features/cms/useArticleSuspend";
import { Article } from "../../../gateways/cms/article";
import { useGetDailyScheduleQuery, useUpdateDailyScheduleMutation, useUpdateMonthlyScheduleMutation  } from "../../../gateways/cms/articleApi";
//import { useGetDailyScheduleQuery } from "../../../gateways/cms/scheduleApi";
import { useGetMyUserQuery } from "../../../gateways/cms/userApi";
//import { useGetDailyScheduleQuery, useGetMonthlyScheduleQuery } from "../../../gateways/cms/scheduleApi";
import { useGetDeliveryGroupQuery } from "../../../gateways/cms/deliveryGroupApi";
import { useRouterModal } from "../../../features/shared/useModal";
import { useGetDefaultSchemaQuery } from "../../../gateways/cms/schemaApi";
import { OptionType } from "../../../gateways/cms/schema";
import { OrganizationName } from "../../../components/OrganizationName";
import { confirmWithStyle } from "../../../components/MyConfirm";

import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import ButtonMUI from "@mui/material/Button";
import iconCalendar from "../../../assets/icons/icon_calendar.svg";
//import { useUpdateDailyScheduleMutation, useUpdateMonthlyScheduleMutation } from "../../../gateways/cms/scheduleApi";
import { useSelector } from "react-redux";
import { selectOrderedArticles } from "../../../features/cms/orderedArticlesSlice";

// style
// const varCss = {
//     scHeaderH: '48px',
//     scItemH: '120px',
//     border1: '#dadce0 1px solid',
//     periodHeaderW: '460px',
//     chkboxSize: '24px',
// };
const PageTitle = styled("section")`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 24px;
    // margin-bottom: 16px;

    h1,
    h2 {
        font-size: 24px;
        font-weight: normal;
        margin: 0;
        margin: 0 16px 0 0;
    }
    h2 small {
        margin: 0 0 0 16px;
        font-size: 60%;
    }

    /* & > div:first-child{
        display: flex;
    } */
    & > div:last-child {
        .MuiButton-root {
            width: 116px;
            margin-left: 16px;
        }
    }
`;
const BarList = styled("section")`
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-start;
    margin-bottom: 16px;
    padding: 0 24px;

    select {
        height: 32px;
    }

    .settings {
        &.ScheduleType-monthly1 button:nth-of-type(3),
        &.ScheduleType-monthly2 button:nth-of-type(2),
        &.ScheduleType-monthly3 button:nth-of-type(1),
        &.ScheduleType-daily button:nth-of-type(4) {
            color: #fff;
            background-color: #3a3e5d;
            border-color: #3a3e5d;
        }
    }
`;
const Button = styled(ButtonMUI)`
    height: 32px;
    border-radius: 2px;
`;
const InputDate = styled("input")`
    padding: 4px;

    &::-webkit-calendar-picker-indicator {
        background: transparent url(${iconCalendar}) center center no-repeat scroll;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
            background-color: #00000018;
        }
    }
`;

const Loading = styled("section")`
    position: relative;
    left: 45%;
    top: 35%;
    b {
        font-size: 12px;
        color: #A9A9A9;
        font-weight: normal;
    }
`; 

export const Schedule = () => {
    const { id } = useParams();
    const deliveryGroupId = Number(id);
    const openModal = useRouterModal();
    const [searchParams, setSearchParams] = useSearchParams();
    const [viewType, setViewType] = useState("daily");
    const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
    const [months, setMonths] = useState(1);
    const articleSuspend = useArticleSuspend();
    const deliveryGroup = useGetDeliveryGroupQuery(deliveryGroupId);
    const [monthlyScheduleUpdate] = useUpdateMonthlyScheduleMutation();
    const [dailyScheduleUpdate] = useUpdateDailyScheduleMutation();
    const orderedArticles = useSelector(selectOrderedArticles);
    const navigate = useNavigate();
    const updateSchedule = {
        startDate: startDate,
        schedule: orderedArticles.map((a, order) => ({ id: a.id, order })),
    }

    const handleSave = async () => {
        setArticleTypeParam(articleTypes.join());
        setDisplayCountPerHour(displayCountPerHour);
        if (viewType === "monthly") {
            await monthlyScheduleUpdate({
                deliveryGroupId,
                body: updateSchedule,
            });
        } else if (viewType === "daily") {
            await dailyScheduleUpdate({
                deliveryGroupId,
                body: updateSchedule,
            });
        } else {
            throw new Error("invalid view type");
        }
        articles.refetch();
        navigate(`/delivery-groups/${deliveryGroupId}`);
    };

    const endDate = format(
        subDays(startOfMonth(addMonths(parse(startDate, "yyyy-MM-dd", new Date()), months)), 1),
        "yyyy-MM-dd"
    );

    const articleDuplicate = (id: number, modaloption: string, deliveryGroupId: number) => {
        openModal(`/articles/${id}/duplicate/?type=${modaloption}&deliveryGroupId=${deliveryGroupId}`);
    };

    const articleTypeParam = searchParams.get("articleType") || "";
    const [articleType, setArticleTypeParam] = useState(articleTypeParam);
    const [articleTypes] = useState([articleTypeParam]);
    /*
    const setArticleType = (articleTypeSet: string) => {
        setSearchParams({ ...Object.fromEntries(searchParams.entries()), articleTypeSet });
        setArticleTypeParam(articleTypeSet);
        articles.refetch();
    };
    */
    const defaultQuery = useGetDefaultSchemaQuery();
    const defaultSchema: OptionType[] = defaultQuery.data?.map((t) => ({ value: t.value, label: t.label })) || [];

    const [schemas, setSchemas] = useState<OptionType[]>([]); 
    const setArticleTypes = (articleTypeSet: OptionType[]) => {
        const values: string[] = articleTypeSet.map((t: OptionType) => (String(t.value)));
        const articleTypeIds = values.join();
        //setSearchParams({ ...Object.fromEntries(searchParams.entries()), values });
        setArticleTypeParam(articleTypeIds);

        articles.refetch();
        setSchemas(articleTypeSet);
    };
    let contentTypes:OptionType[] = [];
    if(schemas.length > 0){
        contentTypes = schemas;
    }else{
        contentTypes = defaultSchema;
    }

    const countPerHour = searchParams.get("displayCountPerHour") || "-1";
    const [displayCountPerHour, setCountPerHour] = useState(countPerHour);
    const setDisplayCountPerHour = (displayCountPerHourSet: string) => {
        setSearchParams({ ...Object.fromEntries(searchParams.entries()), displayCountPerHourSet });
        setCountPerHour(displayCountPerHourSet);
        articles.refetch();
    };
    const getLimitDateMessage = (selectDate: string):string|null => {
        // 90日前を取得
        let limit = new Date();
        limit.setDate(limit.getDate() - 90);
        let month = ("0"+limit.getMonth()).slice(-2); 
        let date = ("0"+limit.getDate()).slice(-2); 
        let limitDate = limit.getFullYear() + "-" + month + "-" + date;  
        // 設定日と比較
        if(limitDate > selectDate){
            let message = limitDate + "日以前の掲載順はアーカイブ済みの為確認できません";
            return message;
        } 
        else return null;
    };
    const setSelectDate = (selectDate: string) => {
        let message = getLimitDateMessage(selectDate);
        if(message){
            alert(message);
            return;
        }
        else{
            setStartDate(selectDate);
            articles.refetch();
            navigate(`/delivery-groups/${deliveryGroupId}`);
        }
    };

    const articles = useGetDailyScheduleQuery({
        deliveryGroupId,
        fromDate: startDate,
        toDate: endDate,
        articleType: String(articleType),
        displayCountPerHour: Number(displayCountPerHour),
    });

    const user = useGetMyUserQuery();
    const checkAgent = ():boolean => {
        if(user.data?.checkAgent) return true;
        return false;
    }
    /*
    const dailyArticles = useGetDailyScheduleQuery({
        deliveryGroupId,
        fromDate: startDate,
        toDate: endDate,
        articleType: Number(articleType),
        displayCountPerHour: Number(displayCountPerHour),
    });
    const monthlyArticles = useGetMonthlyScheduleQuery({
        deliveryGroupId,
        fromDate: startDate,
        toDate: endDate,
        articleType: Number(articleType),
        displayCountPerHour: Number(displayCountPerHour),
    });
    */
    const onArticleSuspend = (article: Article, suspended: boolean, deliveryGroupId: number, startDate: string) => {
        if (suspended) {
            // // eslint-disable-next-line no-restricted-globals
            // if (!confirm("コンテンツの掲載を停止してもよろしいですか？")) return;
            (async () => {
                const isConfirmed = await confirmWithStyle({
                    title: "掲載を一時停止",
                    body: "コンテンツの掲載を一時停止します。よろしいですか？",
                    okButtonText: "一時停止",
                    destructive: true,
                });
                if (isConfirmed) {
                    articleSuspend(article, suspended, deliveryGroupId, startDate);
                    articles.refetch();
                }
            })();
        } else {
            // // eslint-disable-next-line no-restricted-globals
            // if (!confirm("コンテンツの掲載停止を解除してもよろしいですか？")) return;
            (async () => {
                const isConfirmed = await confirmWithStyle({
                    title: "掲載",
                    body: "掲載を一時停止中です。掲載してもよろしいですか？",
                    okButtonText: "掲載",
                });
                if (isConfirmed) {
                    articleSuspend(article, suspended, deliveryGroupId, startDate);
                    articles.refetch();
                }
            })();
        }
        // articleSuspend(article, suspended);
    };

    if (articles.isFetching) {
        return (
            <MainLayout title="掲載スケジュール">
                <Loading>
                    <ReactLoading type="bars" color="#ADD8E6" height={'-1%'} width={'7%'} />
                    <b>掲載データ取得中</b>
                </Loading>
            </MainLayout>
        );
    };

    return (
        <MainLayout title="掲載スケジュール">
            <PageTitle>
                <Stack spacing={1} direction="row">
                    {deliveryGroup.data && <OrganizationName organizationId={deliveryGroup.data?.organizationId} />}
                    <h2>
                        {deliveryGroup.data?.name}
                        <small>( {deliveryGroup.data?.terminals?.map((terminal) => terminal.name).join(", ")} )</small>
                    </h2>
                </Stack>

                <Stack spacing={1} direction="row">
                    <Button variant="outlined" disableElevation href="/delivery-groups">
                        {" "}
                        一覧へ戻る{" "}
                    </Button>
                    {(viewType === "daily" && !checkAgent()) && (
                        <Button variant="contained" disableElevation onClick={handleSave}>
                            {" "}
                            保存更新{" "}
                        </Button>
                    )}
                </Stack>
                {/*
                <Link to="/delivery-groups">一覧へ戻る</Link>
                <button>保存</button>
                */}
            </PageTitle>

            <BarList>
                <Stack
                    spacing={1}
                    direction="row"
                    className={`settings ScheduleType-${viewType}${viewType === "monthly" ? months : ""}`}
                >
                    <div>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                articles.refetch();
                                setViewType("monthly");
                                setMonths(3);
                            }}
                        >
                            3ヶ月
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                articles.refetch();
                                setViewType("monthly");
                                setMonths(2);
                            }}
                        >
                            2ヶ月
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                articles.refetch();
                                setViewType("monthly");
                                setMonths(1);
                            }}
                        >
                            1ヶ月
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                articles.refetch();
                                setViewType("daily");
                            }}
                        >
                            日
                        </Button>
                    </div>
                    <InputDate type="date" value={startDate} onChange={(e) => setSelectDate(e.target.value)} />
                    {/* {viewType} - {startDate} - {endDate} */}
                </Stack>
            </BarList>

            <BarList>
                <Stack spacing={1} direction="row">
                    <SelectScheduleArticleType
                        value={articleTypes}
                        onChange={setArticleTypes}
                        defaultOption={<option value="">全てのコンテンツ</option>}
                        defaultSchema={contentTypes}
                    />
                    {/*<SelectArticleType
                        value={articleType}
                        defaultOption={<option value="">全てのコンテンツ</option>}
                        onChange={setArticleType}
                    />*/}
                    {/*<SelectDisplayCountPerHour value={displayCountPerHour} onChange={setDisplayCountPerHour} /> */}
                </Stack>
            </BarList>

            {viewType === "daily" && (
                <DailySchedule
                    deliveryGroupId={deliveryGroupId}
                    date={startDate}
                    onArticleDuplicate={articleDuplicate}
                    onArticleSuspend={onArticleSuspend}
                    articles={articles}
                    checkAgent={checkAgent()}
                />
            )}
            {viewType === "monthly" && (
                <MonthlySchedule
                    deliveryGroupId={deliveryGroupId}
                    fromDate={startDate}
                    toDate={endDate}
                    onArticleDuplicate={articleDuplicate}
                    onArticleSuspend={onArticleSuspend}
                    className={`ScheduleType-${viewType}${viewType === "monthly" ? months : ""}`}
                    articles={articles}
                    checkAgent={checkAgent()}
                />
            )}
        </MainLayout>
    );
};
